<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<script>
  import Layout from "@/views/layouts/main";
  import MonitorHeader from "./monitor-header.vue";
  import {
    list,
    forceLogout
  } from "@/api/monitor/online";

  /**
   * 系统监控--在线用户
   */
  export default {
    components: {
      Layout,
      MonitorHeader


    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "系统监控",
            active: true
          }
        ],
        pageData: {
          ipaddr: "",
          userName: "",
          pageNum: 1,
          pageSize: 10,
        },
        onlineUserList: [],
        checkedList: [],
        allChecked: false
      }
    },
    created() {
      this.getList();

    },
    computed: {
      rows() {
        return this.onlineUserList.length;
      },

    },

    methods: {
      /** 查询登录日志列表 */
      getList() {
        list(this.pageData).then(res => {
          if (res.status) {
            this.onlineUserList = res.data
            this.pageData.total = res.total;
            this.pageData.pageNum = res.pageNum;
            this.pageData.pageSize= res.pageSize;
          }
        });
      },

      /** 强退按钮操作 */
      handleForceLogout(row) {
        this.$confirm('是否确认强退名称为"' + row.userName + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return forceLogout(row.token);
        }).then(() => {
          this.getList();
          this.$message({
            type: 'success',
            message: "强退成功"
          });
        })
      },
      restParams(){
        this.pageData={}
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val;
        this.getList();
      },
      // checkedAll() {
      //   if(this.allChecked){

      //   }
      //   this.checkedList = []
      // }

    },


  };
</script>

<template>
  <Layout>
    <MonitorHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">

        <div class="card" style="min-height: 500px;">
          <div class="card-body">
            <div class=" check-table-top">
              <form class="flexList" style="flex: 1;">
                <div class="operation-text ">登录地址：</div>
                <input type="text" id="simpleinput" class="form-control w-10 h30 mr-2" v-model="pageData.ipaddr">
                <div class="operation-text ">登录名称：</div>
                <input type="text" id="simpleinput" class="form-control w-10 h30 mr-2" v-model="pageData.userName">
                <button type="button" class="btn btn-info h30 flexList mr-2 " @click="getList()"><i
                    class="iconfont mr-2 icon-mb-search"></i>搜索</button>
                <button type="button" class="btn btn-warning h30 flexList mr-2 " @click="restParams"><i
                    class="iconfont mr-2 icon-zhongzhi"></i>重置</button>
                当前在线人数： <span style="color: #00a2d4">{{pageData.total}}</span>
              </form>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                  <!--  <th style="width: 2%;"><input type="checkbox" value="">
                    </th> -->
                    <th style="width: 4%;"> 序号</th>
                    <th style="width: 12%;">会话编号</th>
                    <th style="width: 8%;">登录名称</th>
                    <th>主机 </th>
                    <th style="width: 8%;">登录地点 </th>
                    <th style="width: 8%;">浏览器</th>
                    <th style="width: 8%;">操作系统 </th>
                    <th style="width: 7%;text-align: center;">会话状态 </th>
                    <th style="width: 11%;">登录时间</th>
                    <th style="width: 8%;">登录时长</th>
                    <th style="width: 6%; text-align: center;">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj, index) in onlineUserList" :key="index">
                    <!-- <td><input type="checkbox" value="" v-model="checkedList"></td> -->
                    <td>{{index+1}}</td>
                    <td>
                      <span class="line1">{{obj.token}}</span>
                    </td>
                    <td>{{obj.userName}}</td>
                    <td>{{obj.ipaddr}}</td>
                    <td>{{obj.loginLocation}}</td>
                    <td>{{obj.browser}}</td>
                    <td>{{obj.os}}</td>
                    <td class="text-center"><span class="badge badge-success">在线</span></td>
                    <td>{{obj.loginTime}}</td>
                    <td>{{obj.loginTimeLength}}</td>

                    <td>
                      <el-button type="danger" size="mini" class="h30" @click="handleForceLogout(obj)"><i
                          class="iconfont icon-come-l  mr-1"></i>强退</el-button>
                      <!-- <button type="button" class="btn btn-danger m-55 h30" ><i
                          class="iconfont icon-come-l mr-1"></i></button> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination
                  @size-change="handleSizeChange"
                  background
                  @current-change="handleCurrentChange"
                  :current-page.sync="pageData.pageNum"
                  :page-size="pageData.pageSize"
                  layout="total, sizes,jumper, prev, pager, next"
                  :total="pageData.total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->


    <!-- 弹窗结束 -->
  </Layout>
</template>
