import { getRequest, deleteRequest } from "@/libs/axios";

// 查询在线用户列表
export function list(params) {
  return getRequest("/yethan/monitor/online/list", params);
}

// 强退用户
export function forceLogout(token) {
  return deleteRequest("/yethan/monitor/online/"+token, {});
}
